<template>
  <div class="row">
      <Visualize />
  </div>
  </template>
  
  <script>
  import axios from "axios";
  import lodash from "lodash";
  import Visualize from "./CardVisualize.vue";
  const _ = lodash;
  const point = ["data.data.metrics.equipment_cycles.shift", "data.data.metrics.good_count.shift", "data.data.metrics.elapsed_time.shift", "data.data.asset_name.value", "data.data.category_display_names.process_state_reason", "data.data.derived_metrics.oee.shift", "data.data.derived_metrics.efficiency.shift"];
  const data = [ ["http://10.2.51.241/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.241/api/v0/device"], ["http://10.2.51.240/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.240/api/v0/device"], ["http://10.2.51.242/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.242/api/v0/device"], ["http://10.2.51.239/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.239/api/v0/device"], ["http://10.2.51.238/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.238/api/v0/device"], ["http://10.2.51.237/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.237/api/v0/device"], ["http://10.2.51.236/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.236/api/v0/device"], ["http://10.2.51.235/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.235/api/v0/device"], ["http://10.2.51.234/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.234/api/v0/device"], ["http://10.2.51.233/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.233/api/v0/device"], ["http://10.2.51.232/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.232/api/v0/device"], ["http://10.2.51.243/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.243/api/v0/device"], ["http://10.2.51.231/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.231/api/v0/device"], ["http://10.2.51.244/api/v0/real_time/data?include=derived,category_display_names", "http://10.2.51.244/api/v0/device"] ];
  
  function GDT0() {
      axios.get(data[0][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff1").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[0][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee1").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[0][0]).then((res) => {
          document.querySelector("#inc1").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[0][0]).then((res) => {
          document.querySelector("#gdc1").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[0][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm1").textContent = "R04";
          } else {
              document.querySelector("#mnm1").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm1").textContent = "R04";
      });
  
      axios.get(data[1][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff2").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[1][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee2").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[1][0]).then((res) => {
          document.querySelector("#inc2").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[1][0]).then((res) => {
          document.querySelector("#gdc2").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[1][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm2").textContent = "R06";
          } else {
              document.querySelector("#mnm2").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm2").textContent = "R06";
      });
  
      axios.get(data[2][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff3").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[2][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee3").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[2][0]).then((res) => {
          document.querySelector("#inc3").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[2][0]).then((res) => {
          document.querySelector("#gdc3").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[2][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm3").textContent = "R07";
          } else {
              document.querySelector("#mnm3").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm3").textContent = "R07";
      });
  
      axios.get(data[3][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff4").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[3][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee4").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[3][0]).then((res) => {
          document.querySelector("#inc4").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[3][0]).then((res) => {
          document.querySelector("#gdc4").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[3][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm4").textContent = "R08";
          } else {
              document.querySelector("#mnm4").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm4").textContent = "R08";
      });
  
      axios.get(data[4][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff5").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[4][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee5").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[4][0]).then((res) => {
          document.querySelector("#inc5").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[4][0]).then((res) => {
          document.querySelector("#gdc5").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[4][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm5").textContent = "R09";
          } else {
              document.querySelector("#mnm5").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm5").textContent = "R09";
      });
  
      axios.get(data[5][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff6").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[5][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee6").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[5][0]).then((res) => {
          document.querySelector("#inc6").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[5][0]).then((res) => {
          document.querySelector("#gdc6").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[5][1]).then((res) => {
          if (res) {
              document.querySelector("#mnm6").textContent = "R10";
          } else {
              document.querySelector("#mnm6").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm6").textContent = "R10";
      });
  
      axios.get(data[6][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff7").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[6][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee7").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[6][0]).then((res) => {
          document.querySelector("#inc7").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[6][0]).then((res) => {
          document.querySelector("#gdc7").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[6][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm7").textContent = "R11";
          } else {
              document.querySelector("#mnm7").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm7").textContent = "R11";
      });
  
      axios.get(data[7][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff8").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[7][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee8").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[7][0]).then((res) => {
          document.querySelector("#inc8").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[7][0]).then((res) => {
          document.querySelector("#gdc8").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[7][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm8").textContent = "R12";
          } else {
              document.querySelector("#mnm8").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm8").textContent = "R12";
      });
  
      axios.get(data[8][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff9").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[8][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee9").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[8][0]).then((res) => {
          document.querySelector("#inc9").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[8][0]).then((res) => {
          document.querySelector("#gdc9").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[8][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm9").textContent = "R13";
          } else {
              document.querySelector("#mnm9").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm9").textContent = "R13";
      });
  
      axios.get(data[9][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff10").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[9][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee10").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[9][0]).then((res) => {
          document.querySelector("#inc10").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[9][0]).then((res) => {
          document.querySelector("#gdc10").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[9][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm10").textContent = "R14";
          } else {
              document.querySelector("#mnm10").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm10").textContent = "R14";
      });
  
      axios.get(data[10][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff11").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[10][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee11").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[10][0]).then((res) => {
          document.querySelector("#inc11").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[10][0]).then((res) => {
          document.querySelector("#gdc11").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[10][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm11").textContent = "R16";
          } else {
              document.querySelector("#mnm11").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm11").textContent = "R16";
      });
  
      axios.get(data[11][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff12").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[11][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee12").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[11][0]).then((res) => {
          document.querySelector("#inc12").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[11][0]).then((res) => {
          document.querySelector("#gdc12").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[11][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm12").textContent = "R17";
          } else {
              document.querySelector("#mnm12").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm12").textContent = "R17";
      });
  
      axios.get(data[12][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff13").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[12][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee13").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[12][0]).then((res) => {
          document.querySelector("#inc13").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[12][0]).then((res) => {
          document.querySelector("#gdc13").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[12][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm13").textContent = "R23";
          } else {
              document.querySelector("#mnm13").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm13").textContent = "R23";
      });
  
      axios.get(data[13][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[6]) * 100) + "%";
          document.querySelector("#eff14").textContent =
              "EFFICENCY:   " + percentagevalue;
      });
      axios.get(data[13][0]).then((res) => {
          const percentagevalue = Math.round(_.get(res, point[5]) * 100) + "%";
          document.querySelector("#oee14").textContent = "OEE:   " + percentagevalue;
      });
      axios.get(data[13][0]).then((res) => {
          document.querySelector("#inc14").textContent =
              "INCOUNT:    " + _.get(res, point[0]);
      });
      axios.get(data[13][0]).then((res) => {
          document.querySelector("#gdc14").textContent =
              "GOOD:    " + _.get(res, point[1]);
      });
      axios.get(data[13][1]).then((res) => {
          if (!res) {
              document.querySelector("#mnm14").textContent = "R24";
          } else {
              document.querySelector("#mnm14").textContent = _.get(res, point[3]);
          }
      }).catch((error) => {
          console.error(error);
          document.querySelector("#mnm14").textContent = "R24";
      });
  }
  
  function GDT1() {
      axios.get(data[0][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm1").textContent = formato;
      });
      axios.get(data[0][0]).then((res) => {
          document.querySelector("#rnst1").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[1][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm2").textContent = formato;
      });
      axios.get(data[1][0]).then((res) => {
          document.querySelector("#rnst2").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[2][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm3").textContent = formato;
      });
      axios.get(data[2][0]).then((res) => {
          document.querySelector("#rnst3").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[3][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm4").textContent = formato;
      });
      axios.get(data[3][0]).then((res) => {
          document.querySelector("#rnst4").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[4][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm5").textContent = formato;
      });
      axios.get(data[4][0]).then((res) => {
          document.querySelector("#rnst5").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[5][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm6").textContent = formato;
      });
      axios.get(data[5][0]).then((res) => {
          document.querySelector("#rnst6").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[6][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm7").textContent = formato;
      });
      axios.get(data[6][0]).then((res) => {
          document.querySelector("#rnst7").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[7][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm8").textContent = formato;
      });
      axios.get(data[7][0]).then((res) => {
          document.querySelector("#rnst8").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[8][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm9").textContent = formato;
      });
      axios.get(data[8][0]).then((res) => {
          document.querySelector("#rnst9").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[9][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm10").textContent = formato;
      });
      axios.get(data[9][0]).then((res) => {
          document.querySelector("#rnst10").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[10][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm11").textContent = formato;
      });
      axios.get(data[10][0]).then((res) => {
          document.querySelector("#rnst11").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[11][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm12").textContent = formato;
      });
      axios.get(data[11][0]).then((res) => {
          document.querySelector("#rnst12").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[12][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm13").textContent = formato;
      });
      axios.get(data[12][0]).then((res) => {
          document.querySelector("#rnst13").textContent = _.get(res, point[4]);
      });
  
      axios.get(data[13][0]).then((res) => {
          let value = Math.round(_.get(res, point[2]));
          const hrs = Math.floor(value / 3600);
          const min = Math.floor((value % 3600) / 60);
          const seg = value % 60;
          const formato = `${hrs.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${seg.toString().padStart(2, "0")}`;
          document.querySelector("#rntm14").textContent = formato;
      });
      axios.get(data[13][0]).then((res) => {
          document.querySelector("#rnst14").textContent = _.get(res, point[4]);
      });
  }
  
  function changeCard() {
      const cards = Array.from(document.getElementsByClassName("cardes"));
      cards.forEach((card) => {
          const span = card.querySelector(".std");
          const text = span.textContent.trim();
          card.classList.remove("nj", "rj", "vd");
          if (text === "Slow and/or Small Stops" || text === "Slow w/ Poor Quality" || text === "Running Poor Quality" || text === "Running Normally" ) { card.classList.add("vd"); } 
          else if (text === "Missing Reason" || text === "Down") { card.classList.add("rj"); } 
          else if ( text === "406-ProblemasHotMelt" || text === "306-Cambio de Orden" || text === "201-CambioRollo" || text === "305-TonificacionTinta" || text === "737-Ajuste de Maquina") { card.classList.add("nj"); }
      });
  }
  
  setInterval(GDT0, 5000);
  setInterval(GDT1, 1000);
  setInterval(changeCard, 1000);
  
  export default {
      components: {
          Visualize,
      },
  };
  </script>
  