<script>
export default {
  name: "CardVisualize",
  data() {
    return {
      cards: Array(14).fill(null).map((_, i) => i + 1),
    };
  },
};
</script>

<template>
  <div
    v-for="card in cards"
    :key="card"
    class="col-xs-10 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 pd10"
  >
    <div :id="'change' + card" class="cardes pd15">
      <div class="row">
        <h3 :id="'mnm' + card" class="col-12">...</h3>
        <span :id="'rnst' + card" class="hdr std col-8">...</span>
        <span :id="'rntm' + card" class="hdr tmp col-4">...</span>
        <br><br><hr>
        <h6 :id="'eff' + card" class="dy col-6">...</h6>
        <h6 :id="'oee' + card" class="dy col-6">...</h6>
        <h6 :id="'gdc' + card" class="dy col-6">...</h6>
        <h6 :id="'inc' + card" class="dy col-6">...</h6>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cardes {
  padding: 5px;
  border-radius: 10px;
  transition: transform 0.2s;
  background: linear-gradient( to bottom, rgba(120, 119, 119, 0.8), rgba(42, 39, 39, 0.8), 44%, rgba(0, 0, 0, 1), 45%, rgba(0, 0, 0, 1), 85%, rgba(61, 61, 61, 0.5) );
  height: 100%;
}

.nj {
  padding: 5px;
  border-radius: 10px;
  transition: transform 0.2s;
  background: linear-gradient( to bottom, rgba(234, 125, 0, 0.8), 5%, rgba(167, 56, 0, 0.8), 35%, rgba(209, 98, 0, 0.8), 44%, rgba(0, 0, 0, 1), 45%, rgba(0, 0, 0, 1), 85%, rgba(95, 65, 0, 0.5) );
  height: 100%;
}

.vd {
  padding: 5px;
  border-radius: 10px;
  transition: transform 0.2s;
  background: linear-gradient( to bottom, rgba(2, 177, 2, 0.8), 5%, rgba(0, 129, 0, 0.8), 35%, rgba(0, 100, 0, 0.5), 44%, rgba(0, 0, 0, 1), 45%, rgba(0, 0, 0, 1), 85%, rgba(83, 218, 40, 0.8) );
  height: 100%;
}

.rj {
  padding: 5px;
  border-radius: 10px;
  transition: transform 0.2s;
  background: linear-gradient( to bottom, rgba(208, 0, 0, 0.8), 5%, rgba(136, 0, 0, 0.8), 35%, rgba(255, 0, 0, 0.8), 44%, rgba(0, 0, 0, 1), 45%, rgba(0, 0, 0, 1), 85%, rgba(100, 0, 0, 0.5) );
  height: 100%;
}

.cardes:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.pd10 {
  padding: 10px;
}

.pd15 {
  padding: 15px;
}

.hdr {
  font-size: 90%;
  font-weight: bold;
}

.tmp {
  text-align: left;
}

.std {
  text-align: center;
}

.dy {
  font-size: 110%;
  font-weight: bold;
  color: #fff;
}

hr {
  margin-top: 4px;
  margin-bottom: 4px;
  border: 5px solid;
  border-radius: 5px;
  color: #000;
}
</style>
